/* eslint-disable react/jsx-no-undef */

/* eslint-disable react/jsx-pascal-case */
import { Flex, HStack, Text, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Sleek } from '../../assets/Images';
import LPFooter from '../../components/LPFooter';
import LicensePlateOrVinInputSleek from '../../components/LicensePlateOrVinInputSleek';
import LPNav from '../../components/Nav/LPNav';
import Title from '../../components/Title';
import { COMErrorCodes } from '../../constants/errors';
import { QueryParams } from '../../constants/queryParam';
import { createErrorToast } from '../../utils/toast';

const LPSleek = () => {
  const {
    replace,
    location: { search },
  } = useHistory();
  const toast = useToast();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const errCode = params.get(QueryParams.ERR_CODE);
    if (errCode === COMErrorCodes.NoDealFound) {
      params.delete(QueryParams.ERR_CODE);
      replace({ search: params.toString() });
      toast(
        createErrorToast({
          description:
            "We weren't able to find any information associated with the email you " +
            'provided. Please enter your License Plate or VIN to get started. If you think this ' +
            'is an error, please get in touch with our support team for help.',
        }),
      );
    }
  }, [search]);

  return (
    <>
      <Flex
        background="black"
        h="100vh"
        flexDir="column"
        w="100%"
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <LPNav />
        <Flex
          h={{
            base: 'calc(100vh - 80px - 180px)',
            md: 'calc(100vh - 60px - 180px)',
          }}
          maxW={{
            base: '1000px',
            md: '1200px',
          }}
          w="100%"
          minW="300"
          backgroundSize={{
            base: '50%',
            md: '50%',
            lg: 'contain',
          }}
          backgroundRepeat="no-repeat"
          backgroundPosition={{
            base: 'right 80%',
            md: '110% 70%',
            lg: 'center right',
          }}
          backgroundImage={Sleek}
          flexDir="column"
          textAlign="left"
          justifyContent="center"
          ps={{ base: 0, md: '24px' }}
        >
          <Title
            fontWeight={800}
            fontSize={{
              base: '36pt',
              md: '48pt',
              lg: '52pt',
            }}
            color="white"
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            lineHeight={{
              base: '54px',
              md: '72px',
              lg: '80px',
            }}
            textShadow="2px 2px 8px #000"
          >
            End your lease,
            <Text as="br" />
            keep your car
          </Title>
          <Text
            color="white"
            fontSize={{
              base: '13pt',
              md: '15pt',
              lg: '17pt',
            }}
            fontWeight={{
              base: 400,
            }}
            mt={{
              base: '16px',
              md: '24px',
            }}
            mb="32px"
            display={{ base: 'none', sm: 'block' }}
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            textShadow="2px 2px 8px #000"
          >
            We've secured more than <strong>$500M</strong> in loans{' '}
            <Text
              as="br"
              display={{
                lg: 'none',
              }}
            />
            to over <strong>25,000 customers</strong>
            <Text as="br" /> to help them keep the cars they love.
          </Text>
          <HStack
            align={{
              base: 'center',
              md: 'flex-start',
            }}
            w="100%"
            maxW={{
              base: '100%',
              md: '450px',
            }}
          >
            <Flex
              w="100%"
              px={{
                base: '24px',
                md: '0',
              }}
              justifyContent="center"
            >
              <LicensePlateOrVinInputSleek autoFocus />
            </Flex>
          </HStack>
        </Flex>
        <LPFooter bg="black" color="white" _socialMediaIcon={{ color: 'white' }} />
      </Flex>
    </>
  );
};

export default LPSleek;
