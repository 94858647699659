export enum LDFlags {
  ACQUISITIONS_FLOW = 'acquisitions-flow',
  ACQUISITION_LEAD_GEN = 'acquisition-lead-gen',
  APRIL_23_HOME_PAGE = 'april-23-home-page',
  ATC_API = 'atc-api',
  AUTO_IMPORT_VARIATION = 'auto-import-variation',
  COBUYER_SELECTION_ORDER = 'cobuyer-selection-order',
  COM_700_CREDIT_OPT_IN = 'com-700-credit-opt-in',
  COM_CONDITIONAL_APPROVALS = 'com-conditional-approvals',
  CUSTOMIZE_PACKAGE = 'customize-package',
  DEAL_PROCESSING_ALLY_VIDEO = 'deal-processing-ally-video',
  DISABLE_LEASE_PAYMENT_PRESENTATION = 'disable-lease-payment-presentation',
  DOWN_PAYMENTS = 'down-payments',
  FLOW_SHOPPING_CART = 'flow-shopping-cart',
  GET_FEES_THROUGH_TT = 'front-end-get-fees-through-tt',
  HOME_PAGE_VALENTINES_BANNER = 'home-page-valentines-banner',
  KBB_OPTIONS_PAGE = 'kbb-options-page',
  LEASE_END_OPT_OUT = 'lease-end-opt-out',
  LEASE_PEACE = 'lease-peace',
  LOAN_APPLICATION_ACCORDION = 'loan-application-accordion',
  MAIN_ITINERARY = 'client-platform-flow-variant-test',
  METRIC_FINISH_TEST_FLOW = 'finish-test-flow',
  RE_CAPTCHA_ENABLED = 're-captcha-enabled',
  SHOW_HEALTH_TRACKER = 'health-tracker',
  SHOW_MARKETING_BANNER = 'marketing-banner-on-home-page',
  SHOW_SAFETY_MESSAGE = 'show-safety-message',
  SHOW_PAYOFF_CALCULATOR = 'show-buyout-calculator',
  SHOW_VIDEO_CAROUSEL = 'show-video-carousel',
  PAYMENT_MODAL_VARIATION = 'payment-modal-variation',
  PHONE_NUMBER_PLACEMENT_VARIATION = 'phone-number-placement-variation',
  WIDGET_BUTTON = 'widget-button',
  WIDGET_BUTTON_CTA_COLOR = 'lp-vin-widget-cta-color',
  HOME_CLEAN_HERO = 'HomePage-CleanSolidColor-Hero',
  PAYMENT_CALCULATOR_VARIATION = 'payment-calculator-variations',

  // START LML LABS
  REFI_LANDING_PAGE = 'refi-landing-page',
  SANITY_BLOG = 'SanityBlog', // HubSpot > Sanity Blog cutover
  EXP14 = 'EXP-14', // CaseStudies
  LM558 = 'LM-558', // Careers page
}

export type FlagOverrides = {
  [key in LDFlags]?: unknown;
};
