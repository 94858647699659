import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Loading from '../Loading';
import { salesFetch, salesTeamFetch } from '../Sanity/Data';
import { SalesProps } from '../Sanity/Model';
import SalesTeam from './SalesTeam';
import SalesTeamMember from './SalesTeamMember';

const SalesTeamMain = () => {
  const params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [sales, setSales] = useState<SalesProps | null>(null);
  const [salesTeam, setSalesTeam] = useState<SalesProps[] | null>(null);
  const fetch = async (name?: string | null) => {
    setLoading(true);
    if (params.get('name') && params.get('name') !== '') {
      const data: SalesProps | null = await salesFetch(name || '');
      setLoading(false);
      setSales(data);
      return;
    }
    const data: SalesProps[] | null = await salesTeamFetch();
    setLoading(false);
    setSalesTeam(data);
  };
  useEffect(() => {
    fetch(params.get('name'));
  }, [params.get('name')]);
  return (
    <>
      <Box pb={24}>
        {loading ? (
          <Loading />
        ) : sales && params.get('name') ? (
          <>
            <SalesTeamMember member={sales} />
          </>
        ) : salesTeam && salesTeam.length ? (
          <>
            <TitleContainer>
              <Title fontSize={{ base: '36px', md: '52px' }}>Your lease ending experts</Title>
            </TitleContainer>
            <Flex flexWrap="wrap" gap={0}>
              {salesTeam.map((team: SalesProps) => {
                return <SalesTeam sales={team} key={team.name} />;
              })}
            </Flex>
          </>
        ) : (
          <>Something went wrong</>
        )}
      </Box>
    </>
  );
};

export default SalesTeamMain;
