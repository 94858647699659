// sort-imports-ignore
import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Loader from '../components/Loader';
import LERoute from '../components/routeComponents/LERoute';
import { LDFlags } from '../constants/experiments';
import {
  ABOUT_US,
  ABOUT_US_SALES,
  AUTHOR,
  BLOG,
  BLOG_POST,
  BLOG_TAG,
  BUY_YOUR_LEASED_CAR,
  CONTACT_US,
  END_YOUR_LEASE,
  FAQS,
  COM_REDIRECTS,
  COM_REDIRECT_URLS,
  HEALTH_TRACKER,
  HOME,
  HOW_IT_WORKS,
  LEASE_PEACE,
  MONTHLY_PAYMENT_CALCULATOR,
  OPT_OUT,
  PAID_SEARCH,
  PRESS,
  PRIVACY_POLICY,
  REDIRECT_TO_HOME_URLS,
  REDIRECT_TO_GOOGLE_URLS,
  REDIRECT_TO_HOME_URLS_WITH_UTM,
  REFI,
  REVIEWS,
  SANITY_LANDING_PAGE,
  SANITY_LANDING_PAGE_DRILL,
  VANITY_URLS_WITH_UTM,
  MKTG_FACEBOOK,
  MKTG_INSTAGRAM,
  MKTG_GOOGLE,
  MKTG_EMAIL,
  MKTG_DIRECT_MAIL_QR,
  MKTG_YOUTUBE,
  LE_ASSETS,
  CREDIT_UNION_LEASE_END_LOAN_RATES,
  CAR_LOAN_LEASE_BUYOUT,
  KWLP_LEASE_BUYOUT_SELL,
  KWLP_LEASE_BUYOUT,
  KWLP_LEASE_BUYOUT_LOANS,
  KWLP_LEASE_BUYOUT_RATES,
  BLOG_SANITY,
  LP_SLEEK,
  CAREERS_PAGE,
} from '../constants/urls';
import useFlag from '../hooks/useFlag';
import useInactivityLogout from '../hooks/useInactivityLogout';
import PageFor404s from '../pages/404Page';
import EndYourLease from '../pages/EndYourLease';
import LeasePeace from '../pages/LeasePeace';
import HealthTracker from '../pages/healthTracker/HealthTracker';
import OptOut from '../pages/optOut/OptOut';
import Blog from '../pages/Blog/Blog';
import BlogTag from '../pages/Blog/Tag';
import BlogPost from '../pages/Blog/Post';
import Assets from '../pages/LE-Assets/Assets';
import Author from '../pages/Author';
import SanityLandingPage from '../pages/Sanity/Landing/Page';
import RedirectToCom from '../components/routeComponents/RedirectToCom';
import Refi from '../pages/Refinance/Refi';
import MktgGoogle from '../pages/Home/MktgGoogle';
import MktgEmail from '../pages/Home/MktgEmail';
import MktgDirectMailQR from '../pages/Home/MktgDirectMailQR';
import MktgYouTube from '../pages/Home/MktgYouTube';
import MktgFacebook from '../pages/Home/MktgFacebook';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import CreditUnionLeaseEndLoanRates from '../pages/Home/CreditUnionLeaseEndLoanRates';
import CarLoanLeaseBuyout from '../pages/Home/CarLoanLeaseBuyout';
import KwlpLeaseBuyoutSell from '../pages/Home/KwlpLeaseBuyoutSell';
import KwlpLeaseBuyout from '../pages/Home/KwlpLeaseBuyout';
import KwlpLeaseBuyoutLoans from '../pages/Home/KwlpLeaseBuyoutLoans';
import KwlpLeaseBuyoutRates from '../pages/Home/KwlpLeaseBuyoutRates';
import SalesTeamMain from '../pages/AboutUs/SalesTeamMain';
import LPSleek from '../pages/Home/LPSleek';
import Careers from '../pages/Careers/Careers';

const Reviews = React.lazy(() => import('../pages/Reviews'));
const Home = React.lazy(() => import('../pages/Home/HomePage'));

const PaidSearch = React.lazy(() => import('../pages/Home/PaidSearch'));
const AboutUs = React.lazy(() => import('../pages/AboutUs'));
const Faq = React.lazy(() => import('../pages/faq/Faq'));
const HowItWorks = React.lazy(() => import('../pages/howItWorks/HowItWorks'));

const MktgInstagram = React.lazy(() => import('../pages/Home/MktgInstagram'));

const MonthlyPaymentCalculator = React.lazy(
  () => import('../pages/MonthlyPaymentCalculator/PaymentCalculatorPage'),
);
const Press = React.lazy(() => import('../pages/Press'));
const Main = React.lazy(() => import('../pages/layouts/Main'));
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'));
const ContactUs = React.lazy(() => import('../pages/contactUs/ContactUs'));

const Routes: React.FC = () => {
  useInactivityLogout();
  const { search, pathname } = useLocation();
  const [, setQueryParams] = useCookie(CookieKeys.QUERY_PARAMS);

  const showPayoffCalculator = useFlag(LDFlags.SHOW_PAYOFF_CALCULATOR);
  const showHealthTracker = useFlag(LDFlags.SHOW_HEALTH_TRACKER);
  const showLeasePeace = useFlag(LDFlags.LEASE_PEACE);
  const optOutEnabled = useFlag(LDFlags.LEASE_END_OPT_OUT);

  const homeTitle = 'Lease End';
  const isBuyYourLeasedCar = window.location.pathname === BUY_YOUR_LEASED_CAR;
  const homeDescription = isBuyYourLeasedCar
    ? 'Nearing the end of your lease? Keep the car you love—for good. Learn how to buy your ' +
      'leased car with our simple, fast, online process.'
    : 'Buy out your lease & keep your car—from home. Let our lease-end experts handle everything ' +
      'from auto loan to license plate. Get started in just 12 minutes!';
  const homeKeywords =
    'car lease website, lease ending, lease payoff website, end of auto lease, buy your leased car';

  const SANITY_BLOG = useFlag(LDFlags.SANITY_BLOG);
  useEffect(() => {
    if (search) {
      setQueryParams(search);
    }
  }, [search]);

  return (
    <Switch>
      <LERoute
        path={[HOME]}
        exact
        component={Home}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[...REDIRECT_TO_HOME_URLS]}
        exact
        component={Home}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[PAID_SEARCH]}
        exact
        component={PaidSearch}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />

      {/* Keyword Landing Pages */}
      <LERoute
        path={[KWLP_LEASE_BUYOUT_SELL]}
        exact
        component={KwlpLeaseBuyoutSell}
        title={`${homeTitle} - Get a loan to sell your leased car`}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[KWLP_LEASE_BUYOUT]}
        exact
        component={KwlpLeaseBuyout}
        title={`${homeTitle} - Buy out your car lease, hassle-free`}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[KWLP_LEASE_BUYOUT_LOANS]}
        exact
        component={KwlpLeaseBuyoutLoans}
        title={`${homeTitle} - Shop top lenders, get the best loan options`}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[KWLP_LEASE_BUYOUT_RATES]}
        exact
        component={KwlpLeaseBuyoutRates}
        title={`${homeTitle} - Shop top lenders, get the best rates`}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[LP_SLEEK]}
        exact
        component={LPSleek}
        title="Car Loans To End Your Lease Without The Credit Union from LeaseEnd.com"
        description="Get car and auto lease buy out loan rates as good as a credit union, but without the hassle. "
        keywords={homeKeywords}
      />
      {/* EXP-1 URLs */}
      <LERoute
        path={[CREDIT_UNION_LEASE_END_LOAN_RATES]}
        exact
        component={CreditUnionLeaseEndLoanRates}
        title="Car Loans To End Your Lease Without The Credit Union from LeaseEnd.com"
        description="Get car and auto lease buy out loan rates as good as a credit union, but without the hassle. "
        keywords={homeKeywords}
      />
      <LERoute
        path={[CAR_LOAN_LEASE_BUYOUT]}
        exact
        component={CarLoanLeaseBuyout}
        title="Car and Auto Lease Buyout Loans from LeaseEnd.com"
        description="The easy way to end your car or auto lease. Enter your license plate number or VIN to get started"
        keywords={homeKeywords}
      />
      {/* Marketing URLs */}
      <LERoute
        path={[MKTG_YOUTUBE]}
        exact
        component={MktgYouTube}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[MKTG_FACEBOOK]}
        exact
        component={MktgFacebook}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[MKTG_INSTAGRAM]}
        exact
        component={MktgInstagram}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[MKTG_GOOGLE]}
        exact
        component={MktgGoogle}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[...REDIRECT_TO_GOOGLE_URLS]}
        exact
        component={MktgGoogle}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[MKTG_EMAIL]}
        exact
        component={MktgEmail}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={[MKTG_DIRECT_MAIL_QR]}
        exact
        component={MktgDirectMailQR}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      {/* End marketing URLs */}
      <LERoute
        path={[REFI]}
        exact
        component={Refi}
        title={homeTitle}
        description={homeDescription}
        keywords={homeKeywords}
      />
      <LERoute
        path={REDIRECT_TO_HOME_URLS_WITH_UTM}
        render={() => (
          <Redirect
            to={VANITY_URLS_WITH_UTM[pathname.toLowerCase() as keyof typeof VANITY_URLS_WITH_UTM]}
          />
        )}
        title={homeTitle}
      />
      <LERoute
        path={COM_REDIRECT_URLS}
        render={() => (
          <RedirectToCom to={COM_REDIRECTS[pathname.toLowerCase() as keyof typeof COM_REDIRECTS]} />
        )}
        title={homeTitle}
      />
      {showLeasePeace && (
        <LERoute path={LEASE_PEACE} component={LeasePeace} title="Lease Peace | Lease End" />
      )}
      <Route>
        <Main>
          <Suspense fallback={<Loader hideText mt={8} />}>
            <Switch>
              <LERoute path={END_YOUR_LEASE} component={EndYourLease} title="End Your Lease" />
              <LERoute
                path={ABOUT_US}
                component={AboutUs}
                title="About Us | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={ABOUT_US_SALES}
                component={SalesTeamMain}
                title="About Us | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={CAREERS_PAGE}
                component={Careers}
                title="About Us | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={[SANITY_LANDING_PAGE, SANITY_LANDING_PAGE_DRILL]}
                component={SanityLandingPage}
                title="End Your Lease Keep Your Car | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={AUTHOR}
                component={Author}
                title="Author Bio | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={SANITY_BLOG ? BLOG_SANITY : BLOG}
                component={Blog}
                title="Blog | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={BLOG_TAG}
                component={BlogTag}
                title="Blog | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={BLOG_POST}
                component={BlogPost}
                title="Blog | Lease End"
                description="Meet the team at Lease End, a fintech platform made to help drivers end their lease (and keep their car) with a buyout - no dealership trip necessary."
                keywords="car lease website, lease ending, lease payoff website, end of auto lease"
                exact
              />
              <LERoute
                path={FAQS}
                component={Faq}
                title="End of Car Lease Questions & Answers | Lease End"
                description="Do you have questions about the end of your car lease? Get the answers to your car lease FAQs from Lease End's experts, and give us a call to learn more."
                keywords="car lease questions, end of lease questions, car lease questions and answers, car lease faq"
                exact
              />
              <LERoute
                path={HOW_IT_WORKS}
                component={HowItWorks}
                title="End of Lease Options & Process | Lease End"
                description="Want to know your options at the end of a car lease? Whether you want to sell or buy out your car, Lease End will make the process easy. Contact us today!"
                keywords="end of lease options, lease end process, lease end options, car lease end process"
                exact
              />
              <LERoute
                path={PRIVACY_POLICY}
                component={PrivacyPolicy}
                title="Privacy Policy | Lease End"
                description="Lease End is committed to protecting your privacy. Read our privacy policy to learn how we collect and use your information. Contact us with any questions."
                exact
              />
              <LERoute
                path={CONTACT_US}
                component={ContactUs}
                title="Talk to a Lease End Specialist Today! | Lease End"
                description="The specialists at Lease End can answer your questions. Learn about our process and let us find a solution to help you end your car lease easily. Call today!"
                keywords="lease end specialist, end of lease experts, auto lease experts"
                exact
              />
              <LERoute path={PRESS} component={Press} title="Press | Lease End" />
              <LERoute
                path={REVIEWS}
                component={Reviews}
                title="Read Lease End's Company Reviews | Lease End"
                description="Check out Lease End's reviews to hear from lease owners about their experience working with us. Contact our experts to learn more about how we can help you!"
                keywords="lease end reviews, lease end company reviews, lease end department reviews"
                exact
              />
              <LERoute path={LE_ASSETS} component={Assets} title="Assets | Lease End" />
              {showPayoffCalculator && (
                <LERoute
                  path={MONTHLY_PAYMENT_CALCULATOR}
                  component={MonthlyPaymentCalculator}
                  title="Your Car Lease Buyout Estimate | Monthly Payment Calculator"
                  description="End your lease & keep your car with a new monthly payment. Get an estimate for a lease buyout loan based on your credit score & lease payoff amount."
                  keywords="monthly payment calculator, car payment calculator, auto financing calculator"
                  exact
                />
              )}
              {showHealthTracker && (
                <LERoute
                  path={HEALTH_TRACKER}
                  component={HealthTracker}
                  title="Lease End Health Tracker | Lease End"
                  description="Get a FREE report on the status of your leased vehicle. Lease End provides you insight on the health of your lease, your vehicle's projected equity and even the best day to buy you lease out."
                  keywords="lease end health tracker, lease end vehicle health, lease end equity, lease end buyout"
                  exact
                />
              )}
              {optOutEnabled && (
                <LERoute path={OPT_OUT} component={OptOut} title="Opt Out | Lease End" exact />
              )}
              {optOutEnabled && <Redirect from={OPT_OUT.replace('-', '')} to={OPT_OUT} exact />}
              <LERoute path="*" component={PageFor404s} title="404 | Lease End" />
            </Switch>
          </Suspense>
        </Main>
      </Route>
    </Switch>
  );
};

export default Routes;
