import config from '../config';

export const ABOUT_US = '/about-us';
export const ABOUT_US_SALES = '/about-us/sales-team';
export const CAREERS_PAGE = '/careers';
export const REFI = '/refi';
export const AUTHOR = '/author/:slug';
export const FAQS = '/faqs';
export const HOME = '/';
export const END_YOUR_LEASE = '/end-your-lease';
export const LOGIN = '/verify';
export const HOW_IT_WORKS = '/how-it-works';
export const PRIVACY_POLICY = '/privacy-policy';
export const CONTACT_US = '/contact-us';
export const PRESS = '/press';
export const LE_ASSETS = '/assets/files';
export const REVIEWS = '/reviews';
export const PAID_SEARCH = '/paid-media';

export const KWLP_LEASE_BUYOUT = '/lease-buyout';
export const KWLP_LEASE_BUYOUT_SELL = '/lease-buyout-sell';
export const KWLP_LEASE_BUYOUT_LOANS = '/lease-buyout-loans';
export const KWLP_LEASE_BUYOUT_RATES = '/lease-buyout-rates';

export const LP_SLEEK = '/lp-sleek';

// New divested home page for marketing URLs
export const MKTG_FACEBOOK = '/facebook';
export const MKTG_YOUTUBE = '/youtube';
export const MKTG_INSTAGRAM = '/instagram';
export const MKTG_GOOGLE = '/google';
export const MKTG_EMAIL = '/email';
export const MKTG_DIRECT_MAIL_QR = '/direct-mail-qr';
// end new divested home page URLs
export const CONSUMER_VOICE = '/cv';
export const CJ = '/cj';
export const MONTHLY_PAYMENT_CALCULATOR = '/monthly-payment-calculator';
export const HEALTH_TRACKER = '/health-tracker';
export const OPT_OUT = '/opt-out';
export const BEST_MONEY = '/bestmoney';
export const BUY_YOUR_LEASED_CAR = '/buy-your-leased-car';
export const BLOG_SANITY = '/learn';
export const BLOG = 'https://learn.leaseend.com';
export const BLOG_TAG = '/learn/tag/:tag';
export const BLOG_POST = '/learn/:slug';
export const SANITY_LANDING_PAGE = '/end-my-lease/:slug';
export const SANITY_LANDING_PAGE_DRILL = '/end-my-lease/:slug/:drill';
export const CREDIT_UNION_LEASE_END_LOAN_RATES = '/credit-union-lease-end-loan-rates';
export const CAR_LOAN_LEASE_BUYOUT = '/car-loan-lease-buyout';
export const VANITY_URLS_WITH_UTM = {
  // leaseend.com/KEY: 'UTM'
  '/avoidfees': '/?source=printmail&utm_medium=equifax&utm_campaign=nice_1_5',
  '/breakmylease': '/?source=RRD&utm_medium=experian&utm_campaign=nice_1_5',
  '/buyout': '/?source=printmail&utm_medium=experian&utm_campaign=nice_1',
  '/endmylease': '/?source=printmail&utm_medium=transunion&utm_campaign=test_1_5',
  '/equity': '/?source=printmail&utm_medium=experian&utm_campaign=test_1',
  '/financing': '/?source=printmail&utm_medium=experian&utm_campaign=test_1_5',
  '/go': '/?utm_source=verse&utm_medium=sms&utm_campaign=smsCold&utm_id=smsCold', // Verse
  '/keepequity': '/?source=printmail&utm_medium=transunion&utm_campaign=nice_1',
  '/keepmycar': '/?source=printmail&utm_medium=transunion&utm_campaign=nice_1_5',
  '/options': '/?source=printmail&utm_medium=experian&utm_campaign=nice_1_5',
  '/ownmylease': '/?source=RRD&utm_medium=experian&utm_campaign=nice_1',
  '/payoff': '/?source=printmail&utm_medium=equifax&utm_campaign=nice_1',
  '/rates': '/?source=printmail&utm_medium=equifax&utm_campaign=test_1',
  '/savetime': '/?source=printmail&utm_medium=equifax&utm_campaign=test_1_5',
  '/unlockequity': '/?source=printmail&utm_medium=transunion&utm_campaign=test_1',
};
export const REDIRECT_TO_HOME_URLS_WITH_UTM = Object.keys(VANITY_URLS_WITH_UTM);

export const REDIRECT_TO_HOME_URLS = [CONSUMER_VOICE, CJ, BUY_YOUR_LEASED_CAR, BEST_MONEY];
export const REDIRECT_TO_GOOGLE_URLS = [
  '/google/pmax-conversion',
  '/google/leads',
  '/google/youtube-campaign',
  '/google/leads-top3kw',
  '/google/leads-b7wk',
  '/google/extended-targeting',
  '/google/credit-union-loans',
  '/branded-search',
  '/google/branded-search',
];

// ----- Flow -----
export const flowBaseUrl = config.flowUrl;
export const DASHBOARD = `${flowBaseUrl}/dashboard`;
export const ACCOUNT_LOGIN = `${flowBaseUrl}/login`;
export const CREATE_ACCOUNT = `${flowBaseUrl}/create-account`;
export const FLOW_ENTRY = `${flowBaseUrl}/start`;
export const GETTING_STARTED = `${flowBaseUrl}/flow/getting-started`;
export const PAYMENTS = `${flowBaseUrl}/payments`;

// COM  Redirects
export const COM_REDIRECTS = {
  '/login': ACCOUNT_LOGIN,
  '/license-plate': FLOW_ENTRY,
  '/vin': FLOW_ENTRY,
  '/create-account': CREATE_ACCOUNT,
  '/payments': PAYMENTS,
};

export const COM_REDIRECT_URLS = Object.keys(COM_REDIRECTS);

// Lease Peace
export const LEASE_PEACE = '/lease-peace';

// Reviews
export const TRUSTPILOT = 'https://www.trustpilot.com/review/leaseend.com';
export const BBB = 'https://www.bbb.org/us/id/burley/profile/auto/lease-end-llc-1296-1000064691';
export const BBB_SPARK = 'https://www.bbb.org/local/0805/foundation/spark-awards';
export const GOOGLE_REVIEWS =
  'https://www.google.com/maps/place/Lease+End+Headquarters/@42.5373242,-113.7824247,17z/data=!4m8!3m7!1s0x54ab37a3cba77799:0x4ce861bc3fb705f!8m2!3d42.5373203!4d-113.780236!9m1!1b1!16s%2Fg%2F11s34wqfgx';

// Privacy Policy
export const GOOGLE_PRIVACY = 'https://www.google.com/policies/privacy/';
export const GOOGLE_PRIVACY_PARTNERS = 'http://www.google.com/policies/privacy/partners/';
export const GO_OPT_OUT = 'https://tools.google.com/dlpage/gaoptout';
export const GOOGLE_ADS = 'https://policies.google.com/technologies/ads';
export const GOOGLE_AUTH = 'https://adssettings.google.com/authenticated';
export const GOOGLE_DISPLAY_ADS =
  'https://adssettings.google.com/authenticated?hl=en#display_optout';
export const NETWORK_AD = 'http://www.networkadvertising.org/managing/opt_out.asp';

// Ally
export const UNDERSTANDING_GAP_COVERAGE =
  'https://www.ally.com/do-it-right/car/understanding-gap-coverage-2/';

// Vimeo video - This has to be a Vimeo link
export const FRONT_PAGE_VIMEO_VIDEO = 'https://player.vimeo.com/video/705836562?h=804410da41';

// Social Media
export const FACEBOOK = 'https://www.facebook.com/leaseendadvisors';
export const LINKEDIN = 'https://www.linkedin.com/company/lease-end/';
export const VIMEO = 'https://vimeo.com/user156421697';
export const YOUTUBE = 'https://www.youtube.com/channel/UCSzt538qbD3gTXiKAARWW1A';
export const INSTAGRAM = 'https://www.instagram.com/lease.end/';
export const TWITTER = 'https://x.com/Lease_End_';

// Marketing Banners
export const WIN_GAS_BANNER_URL = 'https://go.leaseend.com/gas';

// Query Strings
export const FACEBOOK_QUERY_STRING = '?ref=fbk';

// Inc 500
export const INC_5000 =
  'https://www.globenewswire.com/en/news-release/2022/08/16/2498919/0/en/Lease-End-Ranks-No-171-on-the-2022-Inc-5000-Annual-List-of-Fastest-Growing-Companies-with-4-650-Growth.html';

// Google
export const GOOGLE =
  'https://www.google.com/maps/place/Lease+End+Headquarters/@42.5373242,-113.7824247,17z/data=!4m8!3m7!1s0x54ab37a3cba77799:0x4ce861bc3fb705f!8m2!3d42.5373203!4d-113.780236!9m1!1b1!16s%2Fg%2F11s34wqfgx';

// Careers
export const CAREERS = 'https://www.indeed.com/cmp/Lease-End';

// Knowledge Center Articles
export const FOUR_BEFORE = 'https://learn.leaseend.com/4-things-before-buyouts';
export const FIVE_FEES =
  'https://learn.leaseend.com/5-fees-to-watch-for-when-ending-your-car-lease';
export const RESIDUAL_VALUE_QUICK =
  'https://learn.leaseend.com/a-quick-guide-to-residual-value-in-leasing';
export const NEGOTIATE_BUYOUT_PRICE = 'https://learn.leaseend.com/negotiate-buyout-price';
export const BUYING_VS_LEASING = 'https://learn.leaseend.com/buying-vs-leasing';
